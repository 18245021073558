<template>
	<div class="appMain">
		<web-header></web-header>
		<div class="content">
			<div class="content1">
				填写信息（标记*项必须填写）
			</div>
			<div class="formClass">
				<el-form :model="form" ref="ruleForm" :rules="rules" label-width="150px" class="demo-ruleForm">
					<div v-for="(item,index) in formList" :key="index">
						<el-form-item :label="item.label" :prop="item.key" v-if="item.isView == 1">
							<template v-if="item.type == 2">
								<!-- 单列 -->
								<el-select v-model="form[item.key]" :placeholder="'请选择'+item.label"
									v-if="item.sourse == 3">
									<el-option v-for="(it,j) in item.list" :key="j" :label="it.label"
										:value="it.label" />
								</el-select>
								<!-- 所属区域 -->
								<el-cascader v-model="form[item.key]" :options="cityData"
									:props="{value:'name',label:'name'}" v-if="item.sourse == 1" />
								<!-- 职业 -->
								<el-cascader v-model="form[item.key]" :options="item.list"
									:props="{value:'id',label:'name'}" v-if="item.sourse == 2" />
							</template>
							<template v-else>
								<el-input :type="item.label ==='密码'?'password':'text'" v-model="form[item.key]"
									:show-password="item.label ==='密码'" :placeholder="`请输入${item.label}`" />
							</template>
						</el-form-item>
					</div>
					<el-form-item :rules="[{ required: true, message: '验证码必填'}]">
						<div class="yzm">
							<el-input placeholder="请输入短信验证码" v-model="form.code"></el-input>
							<div @click="getCode()">
								<el-button :disabled="btnFlag">{{ result > 0 && result ? time2: "获取验证码" }}</el-button>
							</div>
							<img src="../../assets/login/yzm.png" alt="">
						</div>
					</el-form-item>
					<el-form-item>
						<el-checkbox v-model="checked"></el-checkbox>
						同意遵守用户服务协议。点击查看<span style="color:#39AEFF;cursor: pointer;" @click="getProtocol">服务协议</span>
					</el-form-item>
				</el-form>
				<el-dialog title="服务协议" :visible.sync="dialogVisible" width="1200" class="status_change">
					<div class="dialogContain" style="padding:0 20px;" v-html="protocolText"></div>
					<span slot="footer" class="dialog-footer">
						<el-button style="background-color: #1D5DE0" @click="dialogVisible = false">同 意</el-button>
						<el-button @click="dialogVisible = false">拒 绝</el-button>

					</span>
				</el-dialog>
				<div class="sumbit">
					<el-button @click="sumbitEvt">立即注册</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import webHeader from '../../components/webHeader.vue'
	import md5 from 'js-md5'
	import cityData from '../../utils/city.js';
	import test from '../../utils/test.js'
	export default {
		components: {
			webHeader
		},
		data() {
			return {
				cityData: cityData,
				modelData: [],
				form: {
					code: '',
					password: '',
					place: '',
					industry: ''
				},
				dialogVisible: false,
				checked: true,
				formList: [],
				timer: null,
				result: null, //此变量为截止时间戳与当前时间戳相减的差
				timeStamp: null, //此变量为倒计时截止的时间戳
				time2: null,
				code: '',
				btnFlag: false,
				isSexSelectShow: false,
				rules: {},
				protocolText: '', //协议
			}
		},
		created() {
			this._getSiteRegisterInfo()

		},
		methods: {
			// 获取站点注册信息
			_getSiteRegisterInfo() {
				this.$http.post('getPowerAssign').then(res => {
					if (res.code == 200) {
						this.formList = res.data.map(item => {
							let arr = item.registryInfo.split(":");
							if (item.choose == 1 && item.isView == 1) {
								let message = `请输入正确的${arr[0]}`;
								let validator = (rule, value, callback) => {
									if (value) {
										callback()
									} else {
										callback(new Error(`请输入${arr[0]}`))
									}
								};
								switch (arr[1]) {
									case 'userName':
										message = '英文、符号、数字、中文单一或组合，且长度在2-20';
										validator = (rule, value, callback) => {
											if (value === '') callback(new Error(`请输入用户名`))
											else if (!test.userNameReg(value)) callback(new Error(
												`英文、符号、数字、中文单一或组合，且长度在2-20`))
											else callback()
										}
										break;
									case 'name':
										validator = (rule, value, callback) => {
											if (!test.nameReg(value)) callback(new Error(`请输入真实姓名`))
											else callback()
										}
										break;
									case 'password':
										message = '英文、符号、数字单一或组合，且长度在6位及以上';
										validator = (rule, value, callback) => {
											if (value === '') callback(new Error(`请输入密码`))
											else if (!test.passwordReg(value)) callback(new Error(
												`英文、符号、数字单一或组合，且长度在6位及以上`))
											else callback()
										}
										break;
									case 'idCard':
										validator = (rule, value, callback) => {
											if (!test.idCard(value)) callback(new Error(`请输入正确身份证号`))
											else callback()
										}
										break;
									case 'phoneNumber':
										validator = (rule, value, callback) => {
											if (!test.mobile(value)) callback(new Error(`请输入正确手机号`))
											else callback()
										}
										break;
								}
								this.rules[arr[1]] = [{
									required: true,
									message,
									validator,
									trigger: ['change', 'blur']
								}]
							}
							let list = [];
							if (item.type == 2) {
								switch (item.sourse) {
									case 2:
										let arr = item.list;
										arr.forEach((item, index) => {
											item.children.forEach((jtem, Jindex) => {
												delete jtem.children
											})
										});
										list = arr;
										break;
									case 3:
										item.dto.forEach((item, index) => {
											list.push({
												value: index,
												label: item
											})
										})
										break;
								}
							}

							return {
								label: arr[0],
								key: arr[1],
								isView: item.isView,
								isRequired: item.choose == 1 ? true : false,
								type: item.type,
								sourse: item.sourse,
								selectShowKey: item.type == 2 ? arr[1] + 'PickerShow' : '',
								list
							}
						})
					}
				})
			},
			// 获取站点注册协议
			getProtocol() {
				this.$http.post('getProtocol', {
					name: '注册协议'
				}).then(res => {
					if (res.code == 200) {
						this.protocolText = res.data.content;
						return true
					} else {
						this.$message.error(res.message)
						return false
					}
				}).then(res => {
					if (res) this.dialogVisible = true
				})
			},
			sumbitEvt() {

				this.$refs.ruleForm.validate((valid) => {
					if (!this.checked) return this.$message.error('请先勾选用户服务协议')
					if (!this.form.code) return this.$message.error('验证码必填')
					if (!this.form.password) return this.$message.error('密码必填')
					if (valid) {

						if (this.form.password) {
							this.form.password = md5(this.form.password)
						}
						if (this.form.place) {
							this.form.place = this.modelList.place.join('/')
						}
						if (this.form.industry) {
							this.form.industry = this.form.industry[this.form.industry.length - 1]
						}
						this.$http.post('registerApi', {
							data: JSON.stringify(this.form)
						}).then(res => {
							if (res.code === 200) {
								this.$message.success('注册成功，即将返回登录页面')
								setTimeout(() => {
									this.$router.push({
										path: '/login',
										// query: {
										// 	index: 1
										// }
									})
								}, 2000)
							} else {
								this.$message.error(res.message)
							}
						})
					}

				})
			},
			//倒计时时间
			countTime() {
				//如果result<=0,证明上一次读秒已结束，需要重新设置新的时间戳
				if (!this.result) {
					let currentStamp = new Date().getTime();
					this.timeStamp = new Date().getTime() + 300 * 200; //设置倒计时时间
					this.result = (this.timeStamp - currentStamp) / 1000;
					this.time2 = this.formatSeconds(this.result)
					this.result--;
				}
				this.timer = setInterval(() => {
					this.time2 = this.formatSeconds(this.result)
					this.result--;
					if (this.result === 0) {
						clearInterval(this.timer);
						this.timer = null;
						this.btnFlag = false;
					}
				}, 1000);
			},
			//获取验证码
			getCode() {
				if (this.btnFlag) return
				if (!this.form.phoneNumber) return this.$message.error('请先输入电话号码！')

				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						this.btnFlag = !this.btnFlag;
						this.countTime();
						this.$http.post('registerGetCode', {
							phoneNumber: this.form.phoneNumber
						}).then(res => {
							if (res.code === 200) {
								this.code = res.data
								this.$message.success('请查看你的手机并输入验证码')
							} else {
								this.$message.error(res.message)
							}
						})
					} else {
						this.$message.error('请先完成其他必填项!')
					}
				})
			},
			//时间转换时分秒
			formatSeconds(value) {
				let result = parseInt(value)
				let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
				let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result /
					60 % 60));
				let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
				let res = '';
				if (h !== '00') res += `${h}时`;
				if (m !== '00') res += `${m}分`;
				res += `${s}秒`;
				return res;
			}
		}

	}
</script>

<style lang="less" scoped>
	.appMain {
		background: #eee;
		padding-bottom: 50px;
	}

	.main {
		width: 1200px;
		margin: auto;
	}

	.content {
		width: 1083px;
		height: 1332px;
		background: #FFFFFF;
		margin: 0 auto;
		margin-top: 20px;
		padding: 36px 42px;

		.content1 {
			height: 40px;
			background: #FFDFDF;
			margin: 0 auto;
			font-size: 16px;
			font-family: PingFang SC;
			font-weight: 500;
			color: #39AEFF;
			line-height: 40px;
			text-indent: 20px;
		}

		.formClass {
			margin-top: 36px;

			div {
				font-size: 12px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #999999;
			}

			/deep/.el-input {
				width: 240px;
			}

			.sumbit {
				button {
					width: 178px;
					height: 50px;
					background: #FF0000;
					font-size: 20px;
					font-family: PingFang SC;
					font-weight: 500;
					color: #FEFEFE;
					margin-left: 150px;
					margin-top: 30px;
				}
			}
		}
	}

	/deep/.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	/deep/ .avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}

	.yzm {
		/deep/.el-input__inner {
			font-size: 14px;
			padding: 0 15px 0 30px !important;
		}

		display: flex;
		position: relative;

		/deep/.el-input {
			width: 196px;
			height: 41px;
			border-radius: 5px 0px 0px 5px;

			/deep/.el-input__inner {
				padding: 0 30px;
			}
		}

		img {
			position: absolute;
			width: 12px;
			height: 15px;
			left: 10px;
			top: 12px;
		}

		div {
			width: 108px;
			height: 40px;
			border-radius: 0px 5px 5px 0px;
			font-size: 16px;
			font-family: PingFang SC;
			font-weight: 400;
			color: #010101;
			text-align: center;
			line-height: 38px;
			cursor: pointer;

			button {
				width: 100%;
				height: 100%;
				background: #FFFFFF;
			}
		}
	}

	/deep/.el-dialog {
		// height: 800px;
		overflow: auto;
		margin-top: 50px !important;
	}

	/deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
		background-color: #39AEFF;
		border-color: #39AEFF
	}

	::v-deep .el-dialog {
		border-radius: 10px;

		.el-dialog__header {
			background-color: #FC772F;
			font-size: 18px;
			font-weight: 700;
			text-align: center;

			.el-dialog__title,
			.el-dialog__headerbtn i {
				color: white;
			}
		}

		.el-dialog__body {
			.dialogContain {
				overflow-y: auto;
				height: 400px !important;
			}
		}

		.el-dialog__footer {
			text-align: center;

			.el-button {
				color: #fff;
				font-size: 14px;
				background-color: #E01D1D;
				width: 120px;
				height: 20px;
				line-height: 0;
				border-radius: 10px;
			}
		}

	}
</style>
